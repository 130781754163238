import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
    <div className="header-wrap">
      <header>
        <div className="container">
          <div className="logo">
            <a href="#">
              <img src="Assets/images/logo.png" alt="Real Estate" />
            </a>
          </div>
          <button className="menu-toggle">
            <i className="ri-menu-fill bar-icon"></i>
            <i className="ri-close-fill close-icon"></i>
          </button>
          <div className="header-right">
            <div className="menu">
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">Properties</a>
                </li>
                <li>
                  <a href="#">Agents</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
              </ul>
            </div>
            {/* <div className="cta">
              <a href="#" className="btn btn-outline-primary">
                Log in
              </a>
            </div> */}
          </div>
        </div>
      </header>
    </div>
    
    <div className="banner-wrap">
      <div className="container">
        <div className="banner-box">
          <div className="banner-box__img">
            <img
              src="Assets/images/banner.jpg"
              width="1300"
              height="634"
              alt=""
            />
          </div>
          <div className="banner-box__content">
          <div className="logo text-md-start">
            <a href="#">
              <img src="Assets/images/brr.jpg" alt="Real Estate" />
            </a>
          </div>
            <div className="banner-box__title h1">
              Easy way to find a perfect property
            </div>
          </div>
      
        </div>
      </div>
    </div>
    <div className="countries-wrap">
      <div className="container">
        {/* <div className="countries-wrap__title">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-7 col-md-10">
              <h2 className="h2 text-center">
                We are available in many well-known countries
              </h2>
            </div>
          </div>
        </div> */}
        <div className="countries-list">
          <ul className="row">
            <li className="col-lg-3 col-md-6 col-sm-6">
              <div className="countries-box">
                <div className="countries-box__title">BED ROOM</div>
                <div className="countries-box__img">
                  <img src="Assets/images/1.jpg" alt="" />
                </div>
              </div>
            </li>
            <li className="col-lg-3 col-md-6 col-sm-6">
              <div className="countries-box">
                <div className="countries-box__title">LIVING ROOM</div>
                <div className="countries-box__img">
                  <img src="Assets/images/2.jpg" alt="" />
                </div>
              </div>
            </li>
            <li className="col-lg-3 col-md-6 col-sm-6">
              <div className="countries-box">
                <div className="countries-box__title">KITCHEN</div>
                <div className="countries-box__img">
                  <img src="Assets/images/3.jpg" alt="" />
                </div>
              </div>
            </li>
            <li className="col-lg-3 col-md-6 col-sm-6">
              <div className="countries-box">
                <div className="countries-box__title">WASH ROOM</div>
                <div className="countries-box__img">
                  <img src="Assets/images/4.jpg" alt="" />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    {/* <div className="recently-added-wrap">
      <div className="container">
        <div className="recently-added-wrap__title d-md-flex justify-content-between">
          <h2 className="h2">Recently Added</h2>
          <div className="cta">
            <a href="#" className="btn btn-link">
              See all
            </a>
          </div>
        </div>
        <div className="property-list">
          <ul className="row">
            <li className="col-md-6">
              <div className="property-box">
                <div className="property-box__left">
                  <img src="https://picsum.photos/id/163/158/200" alt="" />
                </div>
                <div className="property-box__right">
                  <div className="property-box__title h3">
                    <a href="#">103/143 West Street, Crows Nest</a>
                  </div>
                  <div className="property-box__amenities">
                    <span>10 Bedroom</span>
                    <span>150 M</span>
                    <span>2 Garage</span>
                  </div>
                  <div className="property-box__post-price">
                    <div className="property-box__post-by">
                      Posted by <a href="#">X Builder</a>
                    </div>
                    <div className="property-box__price">
                      <span className="badge">$45,545</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="col-md-6">
              <div className="property-box">
                <div className="property-box__left">
                  <img src="https://picsum.photos/id/174/158/200" alt="" />
                </div>
                <div className="property-box__right">
                  <div className="property-box__title h3">
                    <a href="#">103/143 West Street, India</a>
                  </div>
                  <div className="property-box__amenities">
                    <span>10 Bedroom</span>
                    <span>150 M</span>
                    <span>2 Garage</span>
                  </div>
                  <div className="property-box__post-price">
                    <div className="property-box__post-by">
                      Posted by <a href="#">X Builder</a>
                    </div>
                    <div className="property-box__price">
                      <span className="badge">$45,545</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="col-md-6">
              <div className="property-box">
                <div className="property-box__left">
                  <img src="https://picsum.photos/id/164/158/200" alt="" />
                </div>
                <div className="property-box__right">
                  <div className="property-box__title h3">
                    <a href="#">103/143 West Street, Crows Nest</a>
                  </div>
                  <div className="property-box__amenities">
                    <span>10 Bedroom</span>
                    <span>150 M</span>
                    <span>2 Garage</span>
                  </div>
                  <div className="property-box__post-price">
                    <div className="property-box__post-by">
                      Posted by <a href="#">X Builder</a>
                    </div>
                    <div className="property-box__price">
                      <span className="badge">$45,545</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="col-md-6">
              <div className="property-box">
                <div className="property-box__left">
                  <img src="https://picsum.photos/id/220/158/200" alt="" />
                </div>
                <div className="property-box__right">
                  <div className="property-box__title h3">
                    <a href="#">103/143 West Street, Crows Nest</a>
                  </div>
                  <div className="property-box__amenities">
                    <span>10 Bedroom</span>
                    <span>150 M</span>
                    <span>2 Garage</span>
                  </div>
                  <div className="property-box__post-price">
                    <div className="property-box__post-by">
                      Posted by <a href="#">X Builder</a>
                    </div>
                    <div className="property-box__price">
                      <span className="badge">$45,545</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="col-md-6">
              <div className="property-box">
                <div className="property-box__left">
                  <img src="https://picsum.photos/id/232/158/200" alt="" />
                </div>
                <div className="property-box__right">
                  <div className="property-box__title h3">
                    <a href="#">103/143 West Street, Crows Nest</a>
                  </div>
                  <div className="property-box__amenities">
                    <span>10 Bedroom</span>
                    <span>150 M</span>
                    <span>2 Garage</span>
                  </div>
                  <div className="property-box__post-price">
                    <div className="property-box__post-by">
                      Posted by <a href="#">X Builder</a>
                    </div>
                    <div className="property-box__price">
                      <span className="badge">$45,545</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="col-md-6">
              <div className="property-box">
                <div className="property-box__left">
                  <img src="https://picsum.photos/id/270/158/200" alt="" />
                </div>
                <div className="property-box__right">
                  <div className="property-box__title h3">
                    <a href="#">103/143 West Street, Crows Nest</a>
                  </div>
                  <div className="property-box__amenities">
                    <span>10 Bedroom</span>
                    <span>150 M</span>
                    <span>2 Garage</span>
                  </div>
                  <div className="property-box__post-price">
                    <div className="property-box__post-by">
                      Posted by <a href="#">X Builder</a>
                    </div>
                    <div className="property-box__price">
                      <span className="badge">$45,545</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div> */}
    <div className="banner-wrap">
      <div className="container">
        <div className="banner-box">
          <div className="banner-box__img">
            <img
              src="Assets/images/banner2.jpg"
              width="1300"
              height="482"
              alt=""
            />
          </div>
          <div className="banner-box__content">
            <h1 className="banner-box__title h1">Find your best Real Estate</h1>
            <div className="banner-box__txt text-ex-large">
              We provide a complete service for the sale, purchase  of
              real estate.
            </div>
            <div className="banner-box__cta">
              <a href="https://api.whatsapp.com/send?phone=7989209030" target='_blank' className="btn btn-primary">  Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-wrap">
      <footer>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4 col-md-8">
              <div className="f-info">
                <div className="f-logo pb-3">
                  <img src="Assets/images/brr.jpg" alt="" />
                </div>
                {/* <p className="text-small pb-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                  enim ad minim veniam, quis nostrud exercitation ullamco laboris
                  nisi ut aliquip ex ea commodo consequat.
                </p> */}
                <div className="d-none d-lg-block">
                  <div className="social-list pb-3 ms-8 ps-4">
                    <ul>
                      <li>
                        <a href="#" className="facebook" target="_blank">
                          <i className="ri-facebook-circle-fill"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="twitter" target="_blank">
                          <i className="ri-twitter-fill"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="instagram" target="_blank">
                          <i className="ri-instagram-fill"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="linkedin" target="_blank">
                          <i className="ri-linkedin-box-fill"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* <p className="text-small">© 2023 . All rights reserved.</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6 pb-4 pb-md-0">
                  <h5 className="h4 pb-3">School & Collages</h5>
                  <div className="quick-links">
                    <ul>
                      <li>
                        <a href="#">Kendriya Vidyalaya 					-	1.5km</a>
                      </li>
                      <li>
                        <a href="#">Sri Chaitanya School				-	1.6km</a>
                      </li>
                      <li>
                        <a href="#">Fort City School 						-	3.1km</a>
                      </li>
                      <li>
                        <a href="#">Raghu Engineering College	-	14.5km</a>
                      </li>
                      <li>
                        <a href="#">MVGR College of Engg.			-	7.1km</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-6 pb-4 pb-md-0">
                  <h5 className="h4 pb-3">Railway / Bus Stand</h5>
                  <div className="quick-links">
                    <ul>  
                      <li>
                        <a href="#">Vizianagaram Railway Station	 			-		1.5km</a>
                      </li>
                      <li>
                        <a href="#">Vizianagaram	RTC Complex	 			-		2.4km</a>
                      </li>
                      
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="d-lg-none pt-4">
            <div className="social-list pb-3">
              <ul>
                <li>
                  <a href="#" className="facebook" target="_blank">
                    <i className="ri-facebook-circle-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="#" className="twitter" target="_blank">
                    <i className="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="#" className="instagram" target="_blank">
                    <i className="ri-instagram-fill"></i>
                  </a>
                </li>
                <li>
                  <a href="#" className="linkedin" target="_blank">
                    <i className="ri-linkedin-box-fill"></i>
                  </a>
                </li>
              </ul>
            </div>
            <p className="text-small">© 2023 . All rights reserved.</p>
            
          </div>
        </div>
      </footer>
    </div>
  </div>
  
  );
}

export default App;
